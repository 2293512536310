@import url(./var.css);
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600&display=swap');

html {
    font-size: 62.5%;
}
  
body {
    min-height: 100vh;
    height: auto;
}

a {
    color: var(--primary-color);
}

/** grid system **/
.container {
    width: 100%;
    max-width: 1032px;
    padding: 0 16px;
    margin: 0 auto;
}

.app-layout {
    min-height: 100vh;
    overflow: hidden;
}

.app-background {
    background-color: #F4F5F7;
}

.app-simple-logo {
    padding: 1.6rem 0;
    line-height: 1;
}

/* sider */
.app-aside {
    min-height: calc(100vh - 64px);
    transition: transform 0.3s ease-in-out;
}

.app-layout .app-aside {
    position: fixed;
    left: 0;
    top: 64px;
    z-index: 1000; /* 確保側邊欄在其他元素之上 */
}

/* 新增: 當側邊欄隱藏時的樣式 */
.app-aside.hidden {
    transform: translateX(-100%);
}

/* 修改側邊欄切換按鈕樣式 */
.sidebar-toggle {
    position: fixed;
    left: 10px;
    top: 74px; /* 調整位置以適應您的佈局 */
    z-index: 1000;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px !important;
    height: 40px !important;
    display: none; /* 修改: 預設不顯示 */
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s, display 0.3s;
}

/* 新增: 在小螢幕上顯示按鈕 */
@media (max-width: 767px) {
    .sidebar-toggle {
        display: flex;
    }
}

.sidebar-toggle:hover {
    background-color: var(--primary-color-dark);
}

/* 調整內容區域的邊距 */
.app-layout .app-header + .ant-layout {
    margin-left: 0;
    transition: margin .3s;
}

.app-layout .app-header + .ant-layout:has(.ant-layout-sider:not(.hidden)) {
    margin-left: 200px;
}

.app-layout .app-header + .ant-layout:has(.ant-layout-sider-collapsed:not(.hidden)) {
    margin-left: 60px;
}

@media (max-width: 767px) {
    .app-layout .app-header + .ant-layout,
    .app-layout .app-header + .ant-layout:has(.ant-layout-sider:not(.hidden)),
    .app-layout .app-header + .ant-layout:has(.ant-layout-sider-collapsed:not(.hidden)) {
        margin-left: 0 !important;
    }
}

.app-layout .app-header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 10;
}

.app-aside-menu {
    margin-top: 20px;
}

.app-aside-menu.ant-menu-light.ant-menu-root.ant-menu-inline,
.app-aside-menu.ant-menu-light>.ant-menu.ant-menu-root.ant-menu-inline,
.app-aside-menu.ant-menu-light.ant-menu-root.ant-menu-vertical,
.app-aside-menu.ant-menu-light>.ant-menu.ant-menu-root.ant-menu-vertical {
    border-inline-end: none;
}

/* content */
.app-layout-content {
    padding: 2.4rem;
}

.app-layout-noSidebar {
    margin-top: 64px;
}

.app-layout-noFooter + .ant-layout-footer {
    display: none;
}

/* Footer */
.app-footer {
    text-align: center;
    font-size: 12px;
}

/* Header */
.app-header {
    box-shadow: inset 2px 0px 3px rgba(0, 0, 0, 0.03), 0px 2px 3px rgba(0, 0, 0, 0.03);
    transition: all 0.3s;
}

.app-header-container {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    position: relative;
}

.app-header-left {
    display: flex;
}

.app-header-logo .app-header-logo-text {
    margin-left: 1rem;
}

.app-header-menu {
    margin-right: 2rem;
    cursor: pointer;
}
  
.app-header-right {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
}

.app-header-right li + li {
    margin-left: 0.8rem;
}

.app-header-right .user {
    cursor: pointer;
}

.app-header-right .user .name {
    margin-left: 0.8rem;
}

.app-header-mobile-menu {
    display: none;
}

/** 登入頁 **/
.app-login {
    min-height: 100vh;
    background-color: #00202b;
    background-size: cover;
    background-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.app-login-header {
    width: 100%;
    padding: 30px 0;
}

.app-login-header-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.app-login-header-menu .menu ol{
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.app-login-header-menu .menu .ant-btn-link {
    color: rgba(255,255,255,0.8);
}

.app-login-form {
    margin-left: auto;
    max-width: 350px;
    width: 100%;
}

.app-login-form .ant-btn.ant-btn-background-ghost:hover {
    border-color: var(--primary-color) !important;
    color: var(--primary-color) !important;
}

.app-login-form h1 {
    text-align: center;
    margin-bottom: 40px;
    color: rgba(255,255,255,0.8);
    line-height: 1.57;
}

.app-login-form .ant-checkbox-wrapper {
    color: rgba(255,255,255,0.8);
}

.app-login-form .ant-input,
.app-login-form .ant-input-affix-wrapper {
    background-color: transparent;
    border: 1px solid rgba(255,255,255,0.2);
    color: rgba(255,255,255,0.8);
}

.app-login-form .ant-input-affix-wrapper > .ant-input {
    border: none;
}

.app-login-form .login-form-forgot {
    float: right;
    color: #009CCD;
}

.app-login-form .ant-input-password-icon {
    color: rgba(255,255,255,0.6);
}

.app-login-form .ant-input-password-icon:hover {
    color: rgba(255,255,255,0.8);
}



.app-login-footer {
    align-self: flex-end;
    text-align: center;
    color: rgb(255 255 255 / 30%);
    width: 100%;
}

.app-login-form .ant-checkbox-wrapper {
    color: rgba(255, 255, 255, 0.8);
}

.ant-layout-sider-collapsed .logo-container {
    padding: 16px;
    text-align: center;
}

.logo-container {
    padding: 24px 16px;
    transition: 0.3s;
}

.logo-container > img {
    max-width: 100%;
}

.stage-name {
    text-align: center;
    color: rgba(255, 255, 255, 0.4);
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 1.57;
}

.app-login-form h1 + .stage-name {
    margin-top: -40px;
    margin-bottom: 40px;
}

.app-login-header-menu .ant-btn.ant-btn-background-ghost,
.app-login-form .ant-btn.ant-btn-background-ghost{
    color: var(--text-color-light-8) !important;
    border-color: var(--text-color-light-4) !important;
}

.app-login-header-menu .ant-btn.ant-btn-background-ghost:hover {
    color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

.app-login-header-menu .ant-btn-link:hover {
    color: var(--primary-color) !important;
}

/* select-menu */
.app-select-menu-item {
    text-align: center;
    padding: 2.4rem 1.2rem;
    cursor: pointer;
    transition: .3s;
}

.app-select-menu-item.disabled {
    color: var(--text-color-light-2);
    cursor: not-allowed;
}

.app-select-menu-item.disabled .icon{
    color: var(--text-color-light-2)
}

.app-select-menu-item:hover {
    transform: translateY(-6px);
}

.app-select-menu-item.disabled:hover {
    transform:none
}

.app-select-menu-item .icon {
    font-size: 3rem;
    color: var(--primary-color);
}

.app-select-menu-item .description {
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
}

/* app-page-title */
.app-page-title {
    margin-top: 2rem;
}

/* app-page-search */
.app-page-search {
    margin-top: 2rem;
}

/* app-page-filter */
.app-page-filter {
    margin-top: 2rem;
    width: 100%;
}

/* app-page-body */
.app-page-body {
    margin-top: 2rem;
}

/* loading */
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 50px);
}

.loading-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem 0;
    height: 100%;
}

.loading-waves {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 40px;
    gap: 3px;
}

.loading-status {
    margin-top: 2rem;
    color: rgba(0, 0, 0, 0.65); /* 提高對比度 */
    font-size: 1.4rem; /* 增加字體大小 */
    font-weight: 600; /* 加粗字體 */
    letter-spacing: 0.8px; /* 增加字距 */
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* 添加陰影 */
    text-align: center; /* 置中對齊 */
}

.wave {
    width: 5px;
    height: 25px;
    background: var(--primary-color);
    opacity: 0.4;
    border-radius: 10px;
    animation: wave 1s ease-in-out infinite;
}

.wave:nth-child(1) {
    animation-delay: 0s;
}

.wave:nth-child(2) {
    animation-delay: 0.1s;
}

.wave:nth-child(3) {
    animation-delay: 0.2s;
}

.wave:nth-child(4) {
    animation-delay: 0.3s;
}

.wave:nth-child(5) {
    animation-delay: 0.4s;
}

@keyframes wave {
    0% {
        transform: scaleY(1);
    }
    50% {
        transform: scaleY(0.5);
    }
    100% {
        transform: scaleY(1);
    }
}

/** 404 **/
.notfound {
    text-align: center;
    padding-top: 4rem;
}

.notfound-img {
    width: 38vw;
    opacity: 0.6;
    margin: 0 auto;
}
  
.notfound-text {
    text-align: center;
}

.notfound-text h1 {
    font-size: 4.8rem;
    margin-bottom: 1rem;
}

.notfound-text p {
    margin-bottom: 3rem;
}

/* 系統設定 */
.list-item {
    display: flex;
    justify-content: space-between;
}

.list-item + .list-item {
    margin-top: 20px;
}

.list-item .list-title {
    display: flex;
    align-items: center;
}

.lockman {
    position: absolute;
    right: 5vw;
    bottom: 14vh;
    z-index: 0;
}

.lockman img {
    opacity: 0.6;
    width: 28vw;
}

/* link */
.app-table-link {
    display: inline-block;
    color: rgba(0, 0, 0, 0.85) !important;
    width: 100%; 
    max-width: 450px;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
}

.ant-table-wrapper.table-loading .ant-table-empty {
    display: none;
}

.ant-table-wrapper:has(.ant-empty) + .loading-animation > .metadata {
    display: none;
}

.filter-separater {
    color: rgba(0, 0, 0, 0.4);
    padding: 0 8px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* tooltip */
.ant-tooltip {
    max-width: 800px;
}

/* spin */
.app-spin-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* 修改Chrome浏览器的滚动条样式 */
::-webkit-scrollbar {
    width: 7px; /* 设置滚动条宽度 */
    height: 7px;
    border-radius: 6px;
}

/* 滚动条滑块样式 */
::-webkit-scrollbar-thumb {
    background-color: rgb(155 155 155 / 50%); /* 设置滑块颜色 */
    border-radius: 6px;
}

/* 滚动条背景样式 */
::-webkit-scrollbar-track {
    background-color: transparent; /* 设置背景颜色 */
    border-radius: 6px;
}

/* 修改Edge浏览器的滚动条样式 */
::-ms-scrollbar {
    width: 6px; /* 设置滚动条宽度 */
    border-radius: 6px;
}

/* 滚动条滑块样式 */
::-ms-thumb {
    background-color: transparent; /* 设置滑块颜色 */
    border-radius: 6px;
}

/* 滚动条背景样式 */
::-ms-track {
    background-color: transparent; /* 设置背景颜色 */
    border-radius: 6px;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}

/* tooltip路徑 */
.app-tooltip-path {
    display: flex;
    padding: 4px;
}

.app-tooltip-path a {
    color: rgba(255, 255, 255, 0.85);
    max-width: 200px;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
}

.app-tooltip-path a + a::before {
    display: inline-block;
    content: '>';
    color: rgba(255, 255, 255, 0.3);
    padding: 0 8px;
    cursor: auto;
}

.ant-layout .ant-layout-sider-trigger {
    color: rgba(0, 0, 0, 0.4);
}

.ant-input-suffix .control {
    cursor: pointer;
    color:rgba(0, 0, 0, 0.4);
    font-size: 12px;
}

.ant-input-suffix .control .icon {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    display: inline-block;
    padding-left: 8px;
}

.ant-popover .ant-popover-inner{
    width: 300px;
}

.ant-popconfirm-message-text {
    width: 100%;
}

/* 甘特圖 */
.gantt_container, .gantt_tooltip {
    font-family: inherit;
}


#controls {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 5px;
}

#controls select,
#controls button {
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 3px;
    border: 1px solid #ced4da;
}

#controls button {
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
}

#controls button:hover {
    background-color: #0056b3;
}

#controls .color-picker-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

#controls .color-picker-container div {
    display: flex;
    align-items: center;
}

#controls .color-picker-container label {
    margin-right: 5px;
    font-size: 14px;
}
  
#controls .color-picker-container input[type="color"] {
    width: 30px;
    height: 30px;
    border: none;
    cursor: pointer;
}
  
.legend-item .color-box {
    border-radius: 3px;
    border: 1px solid #ced4da;
}
  
.legend-item {
    margin-right: 20px;
    display: flex;
    align-items: center;
}
  
.legend-item span {
    margin-left: 5px;
    font-size: 14px;
}

#legend { 
    padding: 10px; display: flex; flex-wrap: wrap;
}
.legend-item {
    display: flex; align-items: center; margin-right: 15px; margin-bottom: 5px;
}
.color-box {
    width: 20px; height: 20px; margin-right: 5px;
}
.color-picker {
    margin-left: 5px;
}
.critical_task {
    background: repeating-linear-gradient(
        45deg,
        #fb8c00,
        #fb8c00 10px,
        #ffa726 10px,
        #ffa726 20px
    ) !important;
    border: 2px solid #e65100 !important;
}

.ant-menu-item-group {
  max-height: calc(100vh - 200px); /* 使用視窗高度減去一個合適的值 */
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}

.ant-menu-item-group-list {
  max-height: none !important;
  overflow-y: visible !important;
}

.ant-menu-item-group-title {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
}

.ant-menu-item-group .ant-menu-item {
  padding-left: 24px !important;
}

/* 為滾動條添加樣式 */
.ant-menu-item-group::-webkit-scrollbar {
  width: 6px;
}

.ant-menu-item-group::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}

.ant-menu-item-group::-webkit-scrollbar-track {
  background-color: transparent;
}

/* 遮罩層樣式 */
.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  transition: opacity 0.3s ease-in-out;
}

/* 調整側邊欄的 z-index，確保它在遮罩層之上 */
.app-aside {
  z-index: 1001;
}

/* 當側邊欄隱藏時，遮罩層也隱藏 */
.app-aside.hidden + .sidebar-overlay {
  display: none;
}

/* 調整內容區域的邊距 */
.app-layout .app-header + .ant-layout {
  margin-left: 0;
  transition: margin .3s;
}

.app-layout .app-header + .ant-layout:has(.ant-layout-sider:not(.hidden)) {
  margin-left: 200px;
}

.app-layout .app-header + .ant-layout:has(.ant-layout-sider-collapsed:not(.hidden)) {
  margin-left: 60px;
}

@media (max-width: 767px) {
  .app-layout .app-header + .ant-layout,
  .app-layout .app-header + .ant-layout:has(.ant-layout-sider:not(.hidden)),
  .app-layout .app-header + .ant-layout:has(.ant-layout-sider-collapsed:not(.hidden)) {
    margin-left: 0 !important;
  }
}