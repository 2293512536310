.dark path[fill="#1d1d1d"] {
    fill:rgb(39, 46, 54);
}

.dark g[stroke="#3e3e3e"] {
    stroke: rgb(67, 80, 94);
}

.dark ellipse[fill="#272727"] {
    fill:rgb(0, 0, 0, .1);
}

/* link */
.dark .app-table-link { 
    color: rgba(255, 255, 255, 0.85) !important;
}

.dark .app-table-link:hover,
.dark .app-table-link:focus {
    color: rgba(255, 255, 255, 1);
}

/* 修改Chrome浏览器的滚动条样式 */
.dark ::-webkit-scrollbar {
    width: 7px; /* 设置滚动条宽度 */
    height: 7px;
    border-radius: 6px;
}

/* 滚动条滑块样式 */
.dark ::-webkit-scrollbar-thumb {
    background-color: #303942; /* 设置滑块颜色 */
    border-radius: 6px;
}

/* 滚动条背景样式 */
.dark ::-webkit-scrollbar-track {
    background-color: #24292d; /* 设置背景颜色 */
    border-radius: 6px;
}

/* 修改Edge浏览器的滚动条样式 */
.dark ::-ms-scrollbar {
    width: 6px; /* 设置滚动条宽度 */
    border-radius: 6px;
}

/* 滚动条滑块样式 */
.dark ::-ms-thumb {
    background-color: #303942; /* 设置滑块颜色 */
    border-radius: 6px;
}

/* 滚动条背景样式 */
.dark ::-ms-track {
    background-color: #24292d; /* 设置背景颜色 */
    border-radius: 6px;
}

.dark .filter-separater {
    color: rgba(255, 255, 255, 0.4);
}

.dark .ant-table-wrapper .ant-table-row-expand-icon:focus,
.dark .ant-table-wrapper .ant-table-row-expand-icon:hover {
    color: rgba(255, 255, 255, 0.85);
}

.dark .ant-layout .ant-layout-sider-trigger {
    color: rgba(255, 255, 255, 0.4);
}

.dark .ant-input-suffix .control {
    color: rgba(255, 255, 255, 0.4);
}

.dark .ant-input-suffix .control .icon {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark .gantt_container,
.dark .gantt_tooltip {
    background-color: transparent;
}

.dark .gantt_grid_scale,
.dark .gantt_task_scale,
.dark .gantt_task_vscroll {
    background-color: transparent;
}

.dark .gantt_grid_scale,
.dark .gantt_task_scale {
    color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.1);
}

.dark .gantt_container {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark .gantt_task .gantt_task_scale .gantt_scale_cell {
    color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.2);
}

.dark .gantt_scale_line {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark .gantt_row,
.dark .gantt_task_row {
    border-color: rgba(255, 255, 255, 0.1);
    background-color: transparent;
}

.dark .gantt_cell {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark .gantt_grid_data .gantt_cell {
    color: rgba(255, 255, 255, 0.85);
}

.dark .gantt_row.odd,
.dark .gantt_task_row.odd {
    background-color: rgba(255, 255, 255, 0.03);
}

.dark .gantt_task_grid_row_resize_wrap .gantt_task_grid_row_resize {
    background-color: rgba(255, 255, 255, 0.1);
}

.dark .gantt_task_cell {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark .gantt_layout_cell_border_right {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark .gantt_grid_data .gantt_row.odd:hover,
.dark .gantt_grid_data .gantt_row:hover {
    background-color: rgba(255,243,161,0.2);
}

.dark .gantt_grid_data .gantt_row.gantt_selected,
.dark .gantt_grid_data .gantt_row.odd.gantt_selected,
.dark .gantt_task_row.gantt_selected {
    background-color: rgba(255,243,161,0.2);
}

.dark .app-spin-wrap {
    background: rgba(0, 0, 0, 0.7);;
}

.dark .gantt_task_row.gantt_selected .gantt_task_cell {
    border-right-color: rgba(255,243,161,0.2);
}

.dark .ant-empty-image path{
    fill: rgb(39, 46, 54);
}